import React, { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import { Divider, Toolbar, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Slide from "@mui/material/Slide";
import { Grid } from "@mui/material";
import "../navigation/css/navigationbar.css";
import "../navigation/css/responsive_navigationbar.css";
import { menulist } from "../navigation/utils/NavUtils";
import NavigationHook from "./navigationHook/NavigationHook";
import nav_logo from "../../assets/logo.png";
import ReusableNav from "./ReusableNav";
import pacweb_logo from "../../assets/logo.png";
import { useSelector } from "react-redux";
import Footer from "./Footer";
import ButtonReusable from "../button/ButtonReusable/ButtonReusable";
import { AiOutlineArrowRight } from "react-icons/ai";
import { FaPhoneAlt } from "react-icons/fa";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FaBars } from "react-icons/fa";
import IconButton from "@mui/material/IconButton";

const HideOnScroll = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Slide appear={false} direction="down" in={isVisible}>
      {children}
    </Slide>
  );
};

const NavigationBar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const nav_reducer_data = useSelector((state) => state.NavigationReducer);
  const { ...param } = NavigationHook();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [menuOpen, setMenuOpen] = useState(false);
  const [isNavBarVisible, setIsNavBarVisible] = useState(true);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsNavBarVisible(false);
      } else {
        setIsNavBarVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!isNavBarVisible) {
      setMenuOpen(false);
    }
  }, [isNavBarVisible]);

  useEffect(() => {
    // Close the menu when the route changes
    setMenuOpen(false);
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <ReusableNav menu_list={menulist} />
          <CssBaseline />
          <HideOnScroll {...props}>
            <AppBar sx={{ backgroundColor: "white" }}>
              <Toolbar sx={{ height: "80px" }}>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item xs={12} md={10}>
                    <div className="navigation_wrapper ">
                      <div className="navigation_container">
                        <div className="navigation_logo">
                          <img
                            src={pacweb_logo}
                            onClick={param.onClickLogo}
                            style={{ cursor: "pointer" }}
                          />
                          <p>PACIFICWEB</p>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "50px",
                          }}
                        >
                          <div className="navigation_nav">
                            {menulist.map((val) => (
                              <p
                                key={val.route}
                                onClick={() =>
                                  param.handleSelectedNavItem(val.route)
                                }
                                className={`menu_items ${
                                  nav_reducer_data.selectedItem === val.route
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                {val.menu}
                              </p>
                            ))}
                          </div>{" "}
                          <div>
                            <Divider orientation="vertical" />
                          </div>
                          <div className="contact_info">
                            <div className="contact_icon">
                              <FaPhoneAlt />
                            </div>
                            <div>
                              <p>Call Anytime</p>
                              <p>09603347000 / 09674767000</p>
                            </div>
                          </div>
                          <div className="menu_icon">
                            <IconButton onClick={toggleMenu}>
                              <FaBars />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Toolbar>
              {isMobile ? (
                <div
                  // id="menu"
                  className={`menu ${menuOpen ? "open" : ""}`}
                >
                  {menulist.map((menuItem) => (
                    <p
                      className={`menu_items ${
                        nav_reducer_data.selectedItem === menuItem.route
                          ? "selected"
                          : ""
                      }`}
                      onClick={() =>
                        param.handleSelectedNavItem(menuItem.route)
                      }
                    >
                      {menuItem.menu}
                    </p>
                  ))}

                  <div style={{ marginLeft: "20px", marginBottom: "16px" }}>
                    <p style={{ margin: 0, fontSize: "12px" }}>Call Anytime</p>
                    <p style={{ margin: 0, fontSize: "10px" }}>
                      09603347000 / 09674767000
                    </p>
                  </div>
                </div>
              ) : null}
            </AppBar>
          </HideOnScroll>
          <Outlet />

          <Toolbar />
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default NavigationBar;
