import React from "react";
import { ACTION_APPLICATION } from "../pages/mainPage/ApplicationPage/constant/Constant";
const initialState = {
  //personal information fields
  firstName: "",
  midName: "",
  lastName: "",
  phoneNo: "",
  email: "",
  dob: "",
  age: "",
  gender: [{ type: "Male" }, { type: "Female" }],
  selectedGender: "",
  nationality: [{ type: "Filipino" }, { type: "Foreign" }],
  selectedNationality: "",
  civilStatus: [{ type: "Single" }, { type: "Married" }],
  selectedCivilStatus: "",
  planData: [],

  choose_plan: [
    { type: "Plan 999" },
    { type: "Plan 1299" },
    { type: "Plan 2399" },
  ],
  chosen_plan: "",

  //chosen intenet plan fields
  planData: [],
  planName: "",
  planAmount: "",
  speed: "",
  installationFee: "",
  staggardPayment: "",
  totalMonthlyPayment: "",
  years: "",

  //billing address fields
  province: "",
  city: "",
  barangay: "",
  house: "",
  street: "",
  perma_house: "",
  block_no: "",
  unit_no: "",
  lot_no: "",

  cityData: [],
  selectedCity: "",
  brgyData: [],
  selectedBrgy: "",

  // billing same with permanent address fields
  billing_address_province_data: [],
  billing_address_city_data: [],
  billing_address_brgy_data: [],
  selected_billing_province: "",
  selected_billing_city: "",
  selected_billing_brgy: "",

  billing_address_province: "",
  billing_address_city: "",
  billing_address_brgy: "",
  billing_address_house_no: "",

  copy_billing_address_province: "",
  copy_billing_address_city: "",
  copy_billing_address_brgy: "",
  copy_billing_address_house_no: "",

  copy_billing_address_street: "",
  copy_billing_address_block: "",
  copy_billing_address_unit_no: "",
  copy_billing_address_lot_no: "",

  // selected file images
  selectedFiles: [],

  //my new way of selecting the province thru state// selecting the actual provDesc, cityDesc etc
  chosenProvince: "",
  chosenCity: "",
  chosenBrgy: "",
  chosenHouseNo: "",
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_APPLICATION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default dataReducer;
