import { Grid, Typography } from "@mui/material";
import React from "react";
import "../navigation/css/reusablenav.css";
import Divider from "@mui/material/Divider";
import { menulist } from "../navigation/utils/NavUtils";
import logo from "../../../src/assets/logo_white.png";
import NavigationHook from "./navigationHook/NavigationHook";
import { useSelector } from "react-redux";
import { FaPhoneAlt } from "react-icons/fa";

const ReusableNav = (props) => {
  const { ...param } = NavigationHook();
  const nav_reducer_data = useSelector((state) => state.NavigationReducer);

  console.log(param.selectedItem);
  return (
    <>
      {/* <Grid container justifyContent="center" spacing={1}>
        <Grid item xs={12} md={10}>
          <div className="wrapper">
            <div className="container">
              <div className="logo">
                <img
                  src={logo}
                  onClick={param.onClickLogo}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div style={{ display: "flex", gap: "20px" }}>
                <div className="nav">
                  {props.menu_list.map((val) => (
                    <p
                      onClick={() => param.handleSelectedNavItem(val.route)}
                      className={`menu_items ${
                        nav_reducer_data.selectedItem === val.route
                          ? "selected"
                          : ""
                      }`}
                    >
                      {val.menu}
                    </p>
                  ))}
                </div>
                <div>
                  <Divider
                    orientation="vertical"
                    sx={{ color: "white", backgroundColor: "white" }}
                  />
                </div>
                <div className="contact_info_reusable">
                  <div className="contact_icon_reusable">
                    <FaPhoneAlt />
                  </div>
                  <div>
                    <p>Call Anytime</p>
                    <p>09603347000 / 09674767000</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid> */}
    </>
  );
};

export default ReusableNav;
